h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: 0.05rem;
}

.header {
	font-weight: 700;
}

h4 {
	font-weight: 500;
}

.bold {
	font-weight: bold !important;
}

.p-1 {
	padding: 1rem !important;
}

.pl-1 {
	padding-left: 1rem !important;
}

.pr-1 {
	padding-left: 1rem !important;
}

.ph-1 {
	padding: 0rem 1rem !important;
}

.m-0 {
	margin: 0rem !important;
}

.ml-0 {
	margin-left: 0rem !important;
}

.ml-1 {
	margin-left: 1rem !important;
}

.mt-1 {
	margin-top: 1rem !important;
}

.mt-2 {
	margin-top: 2rem !important;
}

.mt-3 {
	margin-top: 3rem !important;
}

.mb-0 {
	margin-bottom: 0rem !important;
}

.mb-1 {
	margin-bottom: 1rem !important;
}

.mb-2 {
	margin-bottom: 2rem !important;
}

.mb-3 {
	margin-bottom: 3rem !important;
}

.mr-2 {
	margin-right: 1rem !important;
}

.full-width {
	width: 100%;
}

.button-container {
	width: 100%;
	display: flex;
	margin-top: 2rem;
}

.modal-button-container {
	box-sizing: border-box;
	justify-content: space-between !important;
}

.space-between {
	justify-content: space-between;
}

.h-flex-row {
	display: flex;
	flex-direction: row;
}

.v-flex-col {
	display: flex;
	flex-direction: column;
}

.centered-col-flex-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.centered-row-flex-container {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	align-items: center;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.vertical-button-container {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	flex-direction: column;
}

.form-nav-button-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 6px;
}

.form-nav-button-container > a {
	width: 100%;
}

.form-nav-button {
	border-radius: 5px !important;
	width: 100%;
	color: black !important;
	background-color: #e5e5e5 !important;
	min-height: 32px;
	border: none !important;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04) !important;
	font-weight: 600 !important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.form-nav-button:disabled {
	opacity: 0.3;
}

.button {
	border-radius: 4px !important;
	background-color: white !important;
	color: black !important;
	min-height: 60px;
	border: 1px solid #cccccc !important;
	display: flex;
}

.select-button {
	border-radius: 0px !important;
	background-color: white !important;
	color: black !important;
	min-height: 60px;
	width: 100%;
	margin-top: 1rem !important;
	border: 1px solid #cccccc !important;
	border-radius: 4px !important;
}

.select-button:disabled {
	opacity: 0.3;
}

.button-active {
	border: 1px solid #000000 !important;
	box-shadow: 4px 2px 9px rgba(0, 0, 0, 0.13);
}

.hidden {
	display: none !important;
}

.align-right {
	justify-content: end !important;
}

.disclaimer-text {
	color: #808080;
	font-size: 0.7rem;
}

.left {
	float: left;
	color: #000 !important;
	background-color: #fff !important;
	box-shadow: none !important;
}

.float-right {
	float: right;
}

.left svg {
	margin-right: 8px;
}

.right svg {
	margin-left: 8px;
}

.right {
	background-color: black !important;
	color: white !important;
	display: inline-block !important;
	margin-left: auto !important;
}

.flex-end {
	justify-content: flex-end !important;
}

.font-sm {
	/* font-size: 12.8px; */
	font-size: 0.8rem;
}

.font-md {
	/* font-size: 14.4px !important; */
	font-size: 0.9rem !important;
}

.font-lg {
	/* font-size: 16px; */
	font-size: 1rem;
}
.disabled {
	opacity: 0.3;
}

.radio-checks {
	height: 17px;
	width: 17px;
	border: solid 1px #cccccc;
	margin-left: auto;
	right: 10px;
	display: grid;
	place-items: center;
}

.radio-checks.selected div {
	background-color: #49efb3;
}

.radio-btn {
	border-radius: 50%;
}

.radio-btn.selected div {
	border-radius: 50%;
	width: 11px;
	height: 11px;
	background-color: #49efb3;
}

.checkbox-btn {
	height: 17px;
	width: 17px;
	border: solid 1px #cccccc;
	margin-left: auto;
	right: 10px;
	border-radius: 2px;
	display: grid;
	place-items: center;
}
.select-label {
	flex: 1;
}

.checkbox-btn.selected {
	border: solid 1px #000;
}
.checkbox-btn.selected div {
	width: 11px;
	height: 11px;
	border-radius: 2px;
	background-color: #49efb3;
}
.yes-no-label {
	flex: 1;
}
