.consent-page {
	margin-top: calc(4vh + 3rem);
}

.disabled-link {
	pointer-events: none;
}

/* Small screens - styling for screen with a max width of 600px */
@media only screen and (max-width: 700px) {
	.consent-paper {
		width: 100% !important;
	}
}

/* Medium screens - styling for screen between 600-1200px */
@media only screen and (min-width: 700px) and (max-width: 1200px) {
	.consent-paper {
		width: 75% !important;
	}
}

/* Large screens - styling for screen above 1200px */
@media only screen and (min-width: 1200px) {
	.consent-paper {
		width: 75% !important;
	}
}
