.wrapper {
	margin-top: calc(4vh + 3rem) !important;
}

/* Small screens - styling for screen with a max width of 600px */
@media only screen and (max-width: 700px) {
	.wrapper {
		padding: 1rem;
	}

	.paper-padding {
		padding: 1.5rem !important;
	}
}

/* Medium screens - styling for screen between 600-1200px */
@media only screen and (min-width: 700px) and (max-width: 1200px) {
	.wrapper {
		padding: 1.5rem 5rem;
	}

	.paper-padding {
		padding: 2rem !important;
	}
}

/* Large screens - styling for screen above 1200px */
@media only screen and (min-width: 1200px) {
	.wrapper {
		padding: 1.5rem 5rem;
	}

	.paper-padding {
		padding: 3rem !important;
	}
}
