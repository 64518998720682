.summary-page * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-wrap: anywhere;
}

.summary-page-content {
	padding: 0 !important;
}

.summary-page .page-title {
	margin-bottom: 2em;
	font-weight: 700;
	font-size: 28px;
	text-transform: capitalize;
}

.selected-pharmacy {
	border: solid 2px black;
	padding: 0.8rem 1.6rem;
	border-radius: 4px;
}

.space-between h3 {
	margin-left: 0.8rem;
}

.space-between {
	justify-content: space-between;
}

.summary-page .status-badge {
	padding: 4px 8px;
	font-size: 12px;
	width: fit-content;
	margin-top: 12px;
	border: solid 1px;
	border-radius: 12px;
	display: block;
	margin-bottom: 40px;
	margin-top: -20px;
}

.summary-page .address-lines p {
	font-size: 14px;
	line-height: 24px;
}

.summary-page .ligh-text {
	color: #666666;
}

.summary-page .edit-icon {
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16797 3.33203H3.33464C2.89261 3.33203 2.46868 3.50763 2.15612 3.82019C1.84356 4.13275 1.66797 4.55667 1.66797 4.9987V16.6654C1.66797 17.1074 1.84356 17.5313 2.15612 17.8439C2.46868 18.1564 2.89261 18.332 3.33464 18.332H15.0013C15.4433 18.332 15.8673 18.1564 16.1798 17.8439C16.4924 17.5313 16.668 17.1074 16.668 16.6654V10.832' stroke='black' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.418 2.08417C15.7495 1.75265 16.1991 1.56641 16.668 1.56641C17.1368 1.56641 17.5864 1.75265 17.918 2.08417C18.2495 2.41569 18.4357 2.86533 18.4357 3.33417C18.4357 3.80301 18.2495 4.25265 17.918 4.58417L10.0013 12.5008L6.66797 13.3342L7.5013 10.0008L15.418 2.08417Z' stroke='black' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	width: 20px;
	height: 20px;
	background-size: cover;
	cursor: pointer;
}

.summary-page .health-icon {
	background-repeat: no-repeat;
	width: 38px;
	height: 38px;
	margin-right: 1.6rem;
	display: grid;
	place-items: center;
	background-color: #66666642;
	border-radius: 50%;
}

.summary-page .form-nav-button-container button {
	background-color: black !important;
	color: white !important;
	margin: 1rem;
	display: inline-block !important;
	margin-left: auto !important;
}

.summary-page .text-icons-container {
	display: flex;
	margin: 1.2rem 0;
	align-items: center;
}

.summary-page .text-icons-container h3 {
	margin: 0 0 0.4em 0;
}

.summary-page .css-176wh8e-MuiCircularProgress-circle {
	stroke: rgb(187, 187, 187);
}

.summary-information-page {
	background-color: #f2f2f2;
	padding: 2rem 1rem;
	margin-bottom: 2rem;
}

.summary-page .form-nav-button-container button {
	margin: 1rem;
}

.summary-page .summary-container {
	width: 100% !important;
}

@media only screen and (max-width: 700px) {
	.summary-page .form-nav-button-container button {
		background-color: black !important;
		color: white !important;
		margin: 1rem !important;
		display: inline-block !important;
		margin-left: unset;
	}
}
