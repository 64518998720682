/*
 * This file should contain global css classes that can be leveraged in any page or component file
*/

@import "./styling/fonts.css";
@import "./styling/global.css";
@import "./styling/mui-overrides.css";

/* Small screens - styling for screen with a max width of 600px */
@media only screen and (max-width: 700px) {
	.button-container {
		flex-direction: column;
	}

	.button {
		width: 100%;
		margin-top: 1rem !important;
		font-size: 0.875rem;
		min-width: fit-content !important;
	}

	.MuiBox-root {
		box-sizing: border-box;
	}

	.paper {
		width: 100% !important;
	}
}

/* Medium screens - styling for screen between 600-1200px */
@media only screen and (min-width: 700px) and (max-width: 1200px) {
	.button-container {
		flex-direction: row;
		justify-content: space-between;
	}

	.button,
	.form-nav-button {
		width: 33% !important;
		min-width: fit-content !important;
		font-family: "PoppinsMedium" !important;
	}

	.default-button {
		font-family: "PoppinsMedium" !important;
	}

	.link-button {
		width: 50%;
	}

	.MuiBox-root {
		width: 100%;
		box-sizing: border-box;
	}

	.paper {
		width: 70% !important;
	}
}

/* Large screens - styling for screen above 1200px */
@media only screen and (min-width: 1200px) {
	.button-container {
		flex-direction: row;
		justify-content: space-between;
	}

	.button,
	.form-nav-button {
		width: 33% !important;
		min-width: fit-content !important;
	}

	.link-button {
		width: 50%;
	}

	.MuiBox-root {
		width: 100%;
		box-sizing: border-box;
	}

	.paper {
		width: 60% !important;
	}
}
