.banner-container {
	box-sizing: border-box;
	height: 4vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 15%), 0px 2px 2px 0px rgb(0 0 0 / 0%);
	position: fixed;
	top: 0;
	background-color: #fff;
	z-index: 3;
}

@media (min-width: 700px) {
	.banner-container {
		padding: 2rem 4rem;
	}
}

@media (max-width: 700px) {
	.banner-container {
		padding: 2rem;
	}

	.modal-exit-button,
	.cancel-button {
		margin-left: 0px !important;
	}
}

#logo-banner {
	background-image: url("../../resources/images/eMed_Logo_resized.jpeg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: center;
	height: 3rem;
	width: 8rem;
}

.exit-button {
	min-width: 100px !important;
	font-family: "PoppinsMedium" !important;
}

.modal-exit-button,
.cancel-button {
	border-radius: 5px !important;
	color: black !important;
	min-height: 32px;
	border: none !important;
	display: flex;
	font-weight: 600 !important;
	min-width: 100px !important;
}

.modal-exit-button {
	background-color: red !important;
	border: none !important;
	color: white !important;
	box-shadow: 0px 2px 12px rgb(0 0 0 / 4%) !important;
}

.cancel-button {
	background-color: transparent !important;
	justify-content: space-between;
	color: black !important;
	text-decoration: underline !important;
}
