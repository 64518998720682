.pharmacy-container {
	display: flex;
	border: 1px solid #cccccc;
	padding: 1rem;
	margin: 1rem 0rem;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	align-items: center;
}

.icon {
	width: 25% !important;
}

.address {
	flex-grow: 75% !important;
	align-items: flex-start !important;
}

.address h4 {
	font-size: 20px;
}

.full-address {
	margin-top: 0.25rem;
}

.active {
	border: 1px solid black;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%);
}

h4 {
	font-weight: 900;
	margin: 0 !important;
}
