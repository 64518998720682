* {
	font-family: "PoppinsMedium", sans-serif;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "PoppinsMedium", "PoppinsRegular", "PoppinsBold", "PoppinsBk",
		"PoppinsLight", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: "PoppinsMedium";
	src: local("PoppinsMedium"), url(../fonts/Poppins/Poppins-Medium.ttf) format("opentype");
}

@font-face {
	font-family: "PoppinsBold";
	font-weight: 900;
	src: local("PoppinsBold"), url(../fonts/Poppins/Poppins-Bold.ttf) format("opentype");
}

@font-face {
	font-family: "PoppinsBk";
	font-weight: 900;
	src: local("PoppinsBk"), url(../fonts/Poppins/Poppins-Black.ttf) format("opentype");
}

@font-face {
	font-family: "PoppinsLight";
	font-weight: 900;
	src: local("PoppinsLight"), url(../fonts/Poppins/Poppins-Light.ttf) format("opentype");
}

@font-face {
	font-family: "PoppinsRegular";
	font-weight: 900;
	src: local("PoppinsRegular"), url(../fonts/Poppins/Poppins-Regular.ttf) format("opentype");
}
