.success-page {
	margin-top: calc(4vh + 3rem);
	display: flex;
	flex-direction: column;
}

.h-flex-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

#success-page .MuiSvgIcon-root {
	color: rgba(0, 0, 0, 0.38);
}

#success-page .Mui-completed .MuiSvgIcon-root {
	color: #1ce718 !important;
}

#success-page .MuiStepIcon-text {
	display: none;
}

#success-page .MuiSvgIcon-root circle {
	transform-origin: center center;
	transform: scale(0.45);
}

#success-page .MuiStepLabel-label {
	color: rgba(0, 0, 0, 0.87) !important;
}

.container {
	display: flex;
	flex-direction: column;
	background-color: #f5f5f5;
	border-radius: 12px;
	border: none;
	padding: 2rem;
	margin-top: 2rem;
}

.pharmacy-title {
	font-size: 32px;
	font-weight: bold;
}

.success-steper .MuiStepLabel-label {
	font-family: "PoppinsMedium" !important;
}
