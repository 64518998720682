.search-parameter-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;
	width: 100%;
}

.map-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem 0rem 1rem;
	width: 100%;
}

.map {
	height: 300px;
	width: 100%;
}

.search,
.search-distance,
.pharmacy-name {
	width: 100%;
}

.search {
	margin-top: 2rem;
}

.search-button {
	width: 100%;
	margin-top: 2rem;
}
.pagination-container {
	margin: auto auto 1rem auto;
}

/* Small screens - styling for screen with a max width of 600px */
@media only screen and (max-width: 700px) {
	.search-parameter-container {
		flex-direction: column;
	}

	.search-distance {
		margin-top: 2rem;
	}
}

/* Medium screens - styling for screen between 600-1200px */
@media only screen and (min-width: 700px) and (max-width: 1200px) {
	.search-parameter-container {
		flex-direction: row;
	}

	.pharmacy-name {
		margin-right: 1rem;
	}
}

/* Large screens - styling for screen above 1200px */
@media only screen and (min-width: 1200px) {
	.search-parameter-container {
		flex-direction: row;
	}

	.pharmacy-name {
		margin-right: 1rem;
	}
}
