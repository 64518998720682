.denial-page {
	margin-top: calc(4vh + 3rem);
}

.title {
	margin: 2rem;
}

.span-subtitle {
	font-size: 14px;
	line-height: 18px;
	color: #16162c;
}
