/* Small screens - styling for screen with a max width of 600px */
@media only screen and (max-width: 700px) {
	.MuiFormControlLabel-label {
		font-size: 0.8rem !important;
	}
}

/* Medium screens - styling for screen between 600-1200px */
@media only screen and (min-width: 700px) and (max-width: 1200px) {
	.MuiFormControlLabel-label {
		font-size: 0.9rem !important;
	}
}

/* Large screens - styling for screen above 1200px */
@media only screen and (min-width: 1200px) {
	.MuiFormControlLabel-label {
		font-size: 1rem !important;
	}
}
